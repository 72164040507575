import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getUserLocale } from '@src/utils/cms';
import en from '../locales/en/translations.json';
import es from '../locales/es/translations.json';

const resources = {
  en,
  es
};

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  lng: getUserLocale() ?? navigator.language ?? 'en',
  fallbackLng: 'en',
  resources: resources,
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
