import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { FormInput } from '@ts/components/molecules/AuthFormInput';
import useLoginData from '@src/Components/Login/useLoginData';
import { some } from 'lodash';

import { RouteChildrenProps, Link } from 'react-router-dom';
import { UnauthenticatedFormContainer } from '../templates/UnauthenticatedFormContainer/UnauthenticatedFormContainer';
import { useSelector } from 'react-redux';
import {
  ErrorAlert,
  InfoAlert,
  FormContainer,
  FormAlertContainer,
  InputContainer,
  RowCentered,
  ParagraphCentered
} from '../atoms/containers';
import { DismissAlertBtn, GhostBtn, PrimaryButton } from '../atoms/buttons';
import { FormLogo } from '../atoms/images';
import { VerticalGap } from '../atoms/spacing';
import LocaleSelector from '../organisms/LocaleSelector';
import { Body1Div, H2, H4 } from '../atoms/typography';

export const LoginPage = ({
  history,
  match
}: RouteChildrenProps<{ landing?: string }>) => {
  const {
    formData: { email, password, studyId },
    errors,
    success,
    loginError,
    isSignInClicked,
    handleChange,
    handleDidClickSignIn: _handleDidClickSignIn,
    handleDismissError
  } = useLoginData(history);

  const isLanding = match?.params.landing === 'landing';

  const [isValidStudyId, setIsValidStudyId] = useState(false);
  const [subtitle, setSubtitle] = useState('');
  const [stage, setStage] = useState(0);

  const handleStudyIdContinue = useCallback(() => {
    if (isValidStudyId && isLanding) {
      setStage(1);
    } else {
      setStage(0);
    }
  }, [isValidStudyId, isLanding]);

  useEffect(() => {
    setIsValidStudyId(isLanding && studyId.length > 8);
  }, [studyId, isLanding, isValidStudyId]);

  const loggedOutFromIdle = useSelector(
    (state: any) => state.auth.loggedOutFromIdle
  );

  const {
    screenAndPagesContentConfiguration: {
      sessionContentConfiguration: config
    } = {},
    mainLogo
  } = useTheme();

  const handleDidClickSignIn = useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (isSignInClicked) return;
      _handleDidClickSignIn(event);
    },
    [isSignInClicked, _handleDidClickSignIn]
  );
  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter') return;
      handleDidClickSignIn(event);
    },
    [handleDidClickSignIn]
  );

  const firstPhase = useMemo(() => isLanding && stage == 0, [isLanding, stage]);

  const secondPhase = useMemo(() => {
    if (isLanding) {
      return isValidStudyId && stage == 1;
    } else {
      return true;
    }
  }, [isValidStudyId, stage, isLanding]);

  const loginTitle = useMemo(() => {
    if (isLanding && firstPhase) {
      setSubtitle(
        config?.loginSubtitle || 'To log in, first enter the Study ID'
      );
      return config?.landingFirstTitle || 'Enter Study ID';
    } else if (isLanding && secondPhase) {
      setSubtitle(
        config?.landingSecondTitle ||
          `Enter the credentials associated with Study `
      );
      return config?.landingSecondSubtitle || 'Enter Credentials';
    } else {
      return config?.loginTitle || 'Log in';
    }
  }, [
    isLanding,
    secondPhase,
    firstPhase,
    config?.loginTitle,
    config?.loginSubtitle,
    config?.landingFirstTitle,
    config?.landingSecondTitle,
    config?.landingSecondSubtitle
  ]);

  const backButtonHandle = useCallback(() => {
    setStage(0);
  }, []);

  const alert = useMemo(() => {
    if (loginError || window.location.search.includes('error')) {
      return (
        <ErrorAlert
          role="alert"
          data-cy="error-alert"
          data-testid="error-alert"
        >
          {config?.loginFailureMessage ||
            'The email or password you entered is incorrect, or your account has been locked due to multiple unsuccessful login attempts. Contact support for assistance.'}
          <DismissAlertBtn
            type="button"
            className="close"
            onClick={handleDismissError}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </DismissAlertBtn>
        </ErrorAlert>
      );
    }

    if (loggedOutFromIdle) {
      return (
        <InfoAlert>
          <InputContainer>
            <H4>{config?.loggedOutIdleTitle || 'Logged out.'}</H4>
            <Body1Div>
              {config?.loggedOutIdleMessage ||
                'Your account has been logged out due to inactivity. Please login again to access the portal.'}
            </Body1Div>
          </InputContainer>
        </InfoAlert>
      );
    }

    return null;
  }, [
    loginError,
    loggedOutFromIdle,
    config?.loggedOutIdleMessage,
    config?.loggedOutIdleTitle,
    config?.loginFailureMessage,
    handleDismissError
  ]);

  const validated = useMemo(() => {
    if (!email || !password) return false;
    if (some(errors, Boolean)) return false;
    return true;
  }, [email, password, errors]);

  return (
    <UnauthenticatedFormContainer>
      <LocaleSelector />
      <FormAlertContainer>{alert}</FormAlertContainer>
      <FormLogo
        alt={mainLogo.alt}
        src={isLanding ? '/trial_sync_logo.png' : mainLogo.src}
      />
      <FormContainer>
        <H2 $align="center">{loginTitle}</H2>
        {isLanding && <VerticalGap $gap={24} />}
        {isLanding && (
          <ParagraphCentered>
            <span>{subtitle}</span>
            {secondPhase && <span>{studyId}</span>}
          </ParagraphCentered>
        )}
        {firstPhase && <VerticalGap $gap={24} />}
        {firstPhase && (
          <FormInput
            labelClassName="landing-label"
            label={config?.loginStudyIdField?.label || 'Study ID'}
            inputType="text"
            inputID="studyId"
            data-testid="study-id-input"
            value={studyId}
            required={true}
            onChange={handleChange}
            invalidFeedback={
              config?.loginStudyIdField?.errorMessage ||
              'Please enter your study ID'
            }
            errors={errors}
            success={success}
          />
        )}
        {firstPhase && <VerticalGap $gap={24} />}
        {firstPhase && (
          <RowCentered>
            <PrimaryButton
              data-testid="study-id-submit-btn"
              onClick={handleStudyIdContinue}
              type="button"
              disabled={!isValidStudyId}
              $minWidth="160px"
            >
              {config?.continueLabel || 'Continue'}
            </PrimaryButton>
          </RowCentered>
        )}
        {isLanding && <VerticalGap $gap={24} />}
        {secondPhase && (
          <FormInput
            labelClassName={isLanding ? 'landing-label' : ''}
            label={config?.loginEmailField?.label || 'Email*'}
            inputType="text"
            inputID="email"
            data-testid="email-input"
            placeholder={
              isLanding
                ? ''
                : config?.loginEmailField?.placeholder || 'you@example.com'
            }
            value={email}
            required={true}
            onChange={handleChange}
            invalidFeedback={
              config?.loginEmailField?.errorMessage || 'Please enter your email'
            }
            errors={errors}
            success={success}
          />
        )}
        {secondPhase && <VerticalGap $gap={24} />}
        {secondPhase && (
          <FormInput
            labelClassName={isLanding ? 'landing-label' : ''}
            label={config?.loginPasswordField?.label || 'Password*'}
            showLabel={config?.loginPasswordField?.showLabel}
            hideLabel={config?.loginPasswordField?.hideLabel}
            inputType="password"
            inputID="password"
            data-testid="password-input"
            placeholder={
              isLanding ? '' : config?.loginPasswordField?.placeholder || ''
            }
            value={password}
            required={true}
            onChange={handleChange}
            invalidFeedback={
              config?.loginPasswordField?.errorMessage ||
              'Please enter a password that is at least 8 characters long with at least one letter and one number'
            }
            errors={errors}
            success={success}
            onKeyUp={handleKeyUp}
          />
        )}
      </FormContainer>
      {secondPhase && <VerticalGap $gap={40} />}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {isLanding && secondPhase && (
          <PrimaryButton
            data-testid="loginform-back-btn"
            onClick={backButtonHandle}
            type="button"
            $minWidth="160px"
          >
            {config?.backLabel || 'Back'}
          </PrimaryButton>
        )}
        <div style={{ padding: 10 }}></div>
        {secondPhase && (
          <PrimaryButton
            data-testid="loginform-submit-btn"
            onClick={handleDidClickSignIn}
            type="button"
            disabled={!validated}
            $minWidth="160px"
          >
            {isSignInClicked ? (
              <i className="fas fa-circle-notch fa-spin" />
            ) : isLanding ? (
              config?.loginTitle || 'Log in'
            ) : (
              config?.signInButton || 'Sign in'
            )}
          </PrimaryButton>
        )}
      </div>
      {secondPhase && (
        <Link
          to={{
            pathname: '/forgot-password',
            state: {
              email: email
            }
          }}
        >
          <GhostBtn>
            {config?.forgotPasswordText || 'Forgot Password?'}
          </GhostBtn>
        </Link>
      )}
    </UnauthenticatedFormContainer>
  );
};
